<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex lg12>
        <v-card tile flat>
          <v-layout wrap justify-start pa-4>
            <v-flex xs12 pt-6 pb-4 align-self-center text-left>
              <span style="font-family: poppinsbold; font-size: 20px">
                Add Employee
              </span>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold"> Name </span>

              <v-text-field
                v-model="user.name"
                hide-details
                placeholder="Name"
                dense
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold">Choose Role</span>

              <v-select
                hide-details
                v-model="user.role"
                :items="roles"
                item-text="name"
                item-value="_id"
                dense
                placeholder="Choose Role"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold"> Phone Number </span>

              <v-text-field
                type="number"
                hide-details
                v-model="user.phonenumber"
                placeholder="Phone Number"
                dense
                @wheel="$event.target.blur()"
                :rules="[rules.required, rules.min]"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 pa-2 text-left>
              <span style="font-family: poppinsbold">Date of birth</span>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="user.dob"
                    placeholder="Birthday date"
                    prepend-icon="mdi-calendar"
                    readonly
                    required
                    :rules="[rules.required]"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user.dob"
                  :active-picker.sync="activePicker"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1920-01-01"
                  @change="save"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 pa-2 text-left>
              <span style="font-family: poppinsbold">Gender</span>

              <v-radio-group dense v-model="user.gender" mandatory>
                <v-radio label="Male" value="Male"></v-radio>
                <v-radio label="Female" value="Female"></v-radio>
                <v-radio label="Other" value="Other"></v-radio>
              </v-radio-group>
            </v-flex>
             <v-flex xs12 sm6 pa-2 text-left>
               <span style="font-family: poppinsbold">Password</span>

                          <v-text-field
                            
                            dense
                            hide-details
                            v-model="user.password"
                            :rules="[rules.required]"
                            placeholder="Password"
                            required
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            counter
                            @click:append="show1 = !show1"
                          ></v-text-field>
                        </v-flex>

            <v-flex xs12 md12 pa-6>
              <v-btn
                dark
                depressed
                tile
                large
                color="success"
                class="rounded-xl"
                @click="validateInput()"
              >
                <span style="font-family: poppinsregular; color: white">
                  SUBMIT</span
                ></v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      menu1: false,
      units: null,
      program: "",
      types: null,
      type: null,
      icId: null,
      roles: [],
      name: null,
      description: null,
      user: {
        name: null,
        gender: null,
        dob: null,
        role: null,
        phonenumber: null,
        password: null,
      },
      activePicker: null,
      menu: false,
      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 10 || "Max 10 digits",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        min: (value) => value.length == 10 || "Invalid Phone",
      },
    };
  },
  beforeMount() {
    this.getRoles();
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var month = dt.getMonth();
      dt = dt.toString();
      var strTime = year + "-" + month + "-" + day;
      return strTime;
    },
    save(dob) {
      this.$refs.menu.save(dob);
    },
    getRoles() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/view/all/roles",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.roles = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validateInput() {
      if (!this.user.name) {
        this.msg = "Please Provide Name";
        this.showSnackBar = true;
        return;
      } else if (!this.user.role) {
        this.msg = "Please specify Role";
        this.showSnackBar = true;
        return;
      } else if (!this.user.gender) {
        this.msg = "Please Provide Gender";
        this.showSnackBar = true;
        return;
      } else if (!this.user.dob) {
        this.msg = "Please Provide DOB";
        this.showSnackBar = true;
        return;
      } else if (!this.user.phonenumber) {
        this.msg = "Please Provide Phone No";
        this.showSnackBar = true;
        return;
      } 
       else if (!this.user.password) {
        this.msg = "Please Provide Password";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    add() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/add/employee",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.user,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$router.push("/Admin/Employee");
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
          // this.user = {};
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style>
input {
  color: black !important;
}
</style>
